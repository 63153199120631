export const state = () => ({
	busy: {
		loading: [],
		saving: [],
	},
});

export const getters = {
	isLoading: (state) => (key) => {
		return state.busy.loading.indexOf(key) >= 0;
	},
};

export const actions = {};

export const mutations = {
	startLoading(state, key) {
		if (state.busy.loading.indexOf(key) === -1) {
			state.busy.loading.push(key);
		}
	},
	stopLoading(state, key) {
		const index = state.busy.loading.indexOf(key);
		if (index >= 0) {
			state.busy.loading.splice(index, 1);
		}
	},
	stopLoadingAll(state) {
		state.busy.loading = [];
	},
};
