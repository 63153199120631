import { getCurrentInstance } from "vue";

function getRoot() {
	const vm = getCurrentInstance();
	if (!vm || !vm.proxy)
		throw new Error(
			"Must be called within the setup function of a Vue component."
		);
	return vm.proxy;
}

export const useRoot = () => getRoot();
export const useRouter = () => getRoot().$router;
export const useRoute = () => getRoot().$route;
export const useStore = () => getRoot().$store;
export const useAuth = () => getRoot().$auth;
export const useAxios = () => getRoot().$axios;
export const useConfig = () => getRoot().$config;
