import { ref, onMounted, onBeforeUnmount } from "vue";

export default function useIsMobile() {
	const breakpoint = 992; // corresponds to lg breakpoint in assets/scss/mixins
	const isMobile = ref(false);
	const windowWidth = ref(process.client ? window.innerWidth : null);

	function checkIfMobile() {
		isMobile.value = windowWidth.value < breakpoint;
	}

	function setWindowWidth() {
		if (process.client) {
			windowWidth.value = window.innerWidth;
		}
	}

	onMounted(() => {
		window.addEventListener("resize", () => {
			setWindowWidth();
			checkIfMobile();
		});
	});

	onBeforeUnmount(() => {
		window.removeEventListener("resize", () => {
			setWindowWidth();
			checkIfMobile();
		});
	});

	return {
		isMobile,
	};
}
