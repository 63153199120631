export const state = () => ({
	featureFlags: null,
});

export const getters = {
	getFeatureFlag: (state) => (flagName) => {
		if (!state.featureFlags) {
			return false;
		}
		return state.featureFlags[flagName] === true;
	},
};

export const mutations = {
	setFeatureFlags(state, value) {
		state.featureFlags = value ? this.$util.clone(value) : null;
	},
};
