import { computed } from "vue";
import { renderData } from "@libraryHelpers/dataComponents.js";


export default {
  __name: 'CoreBlockFooterContact',
  props: {
	contact: {
		type: Object,
		required: true,
	},
	site: {
		type: Object,
		required: true,
	},
	teamMemberId: {
		type: String,
		default: null,
	},
},
  setup(__props) {

const props = __props;



const flattenedContact = computed(() => {
	const { type1, type2, type3 } = props.contact;
	return { ...type1, ...type2, ...type3 };
});

const memberId = computed(() => props.site.footer[props.teamMemberId]);

const teamMember = computed(() => {
	return props.site?.teamMembers?.find(
		(t) => memberId.value !== -1 && t.id === memberId.value
	);
});

const displayTitle = computed(() => props.contact.displayTitle);
const title = computed(() => props.contact.title);
const officePhone = computed(() => flattenedContact.value.officePhone);
const officePhoneNumber = computed(() =>
	renderData(officePhone.value.phone || "", props.site)
);
const officePhoneExt = computed(() =>
	renderData(officePhone.value.extension || "", props.site)
);
const altPhone = computed(() => flattenedContact.value.altPhone);
const altPhoneNumber = computed(() =>
	renderData(altPhone.value.phone || "", props.site)
);
const mobile = computed(() => flattenedContact.value.mobile);
const mobileNumber = computed(() =>
	renderData(mobile.value.phone || "", props.site)
);
const fax = computed(() => flattenedContact.value.fax);
const faxNumber = computed(() => renderData(fax.value.phone || "", props.site));

const email = computed(() => {
	const emailVal =
		teamMember.value?.emailAddress || flattenedContact.value.email;
	return renderData(emailVal || "", props.site);
});

const address = computed(() => flattenedContact.value.address);
const streetAddress = computed(() => {
	const addressVal = teamMember.value?.address || address.value.streetAddress;
	return renderData(addressVal || "", props.site);
});
const suite = computed(() =>
	renderData(address.value.suiteNumber || "", props.site)
);
const city = computed(() => {
	const cityVal = teamMember.value?.city || address.value.city;
	return renderData(cityVal || "", props.site);
});
const state = computed(() => {
	const stateVal = teamMember.value?.state || address.value.state;
	return renderData(stateVal || "", props.site);
});
const zip = computed(() => {
	const zipVal = teamMember.value?.postalCode || address.value.zip;
	return renderData(zipVal || "", props.site);
});

const clickToCall = (phoneNumber, extension = "") => {
	const cleanPhoneNumber = phoneNumber && phoneNumber.replace(/[^0-9+]/g, "");
	const cleanExtension = extension && extension.replace(/[^0-9+]/g, "");
	if (cleanExtension) return `tel:${cleanPhoneNumber};${cleanExtension}`;
	return `tel:${cleanPhoneNumber}`;
};

const cleanAddress = () => {
	return (
		`http://maps.google.com/?q=` +
		encodeURIComponent(
			`${address.value.streetAddress} ${address.value.city} ${address.value.state} ${address.value.zip}`
		)
	);
};

return { __sfc: true,props, flattenedContact, memberId, teamMember, displayTitle, title, officePhone, officePhoneNumber, officePhoneExt, altPhone, altPhoneNumber, mobile, mobileNumber, fax, faxNumber, email, address, streetAddress, suite, city, state, zip, clickToCall, cleanAddress }
}

}