
export default {
	name: "CoreBlockFooterOneTrust",
	props: {
		site: {
			type: Object,
			required: true,
		},
	},
	computed: {
		showLink() {
			return (
				this.site.footer?.oneTrust?.useIntegration &&
				!this.site.footer?.oneTrust?.disabled
			);
		},
	},
	methods: {
		emitClick() {
			this.$el.querySelector(".ot-sdk-show-settings").click();
		},
	},
};
