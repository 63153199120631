import { computed, ref, watch } from "vue";
import { useStore } from "../helpers/compositionApi";

export function useEmptyComponent(settings) {
	const store = useStore();
	const { getters } = store;

	const classes = ref([]);
	const emptyContainers = computed(
		() => getters["complianceAgent/getEmptyContainers"]
	);
	const emptyContainerFlagEnabled = computed(() =>
		getters["settings/getFeatureFlag"]("ComplianceEmptyContainer")
	);
	const isEmptyComponent = computed(() =>
		emptyContainers.value?.some((block) => block.id === settings.id)
	);

	function updateClasses() {
		if (!emptyContainerFlagEnabled.value) return;

		if (isEmptyComponent.value) {
			classes.value = ["block_empty"];
		} else {
			classes.value = [];
		}
	}

	watch(
		emptyContainers,
		() => {
			updateClasses();
		},
		{ immediate: true }
	);

	return {
		classes,
	};
}
