
export default {
	name: "CoreBlockDialog",
	inheritAttrs: false,
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: "dialog",
		},
	},
	watch: {
		show(value) {
			if (value) {
				if (this.type === "modal") return this.$refs.dialog.showModal();
				return this.$refs.dialog.show();
			}
		},
	},
	mounted() {
		const dialog = this.$refs.dialog;
		dialog.addEventListener("close", this.handleClose);
	},
	beforeDestroy() {
		const dialog = this.$refs.dialog;
		dialog.removeEventListener("close", this.handleClose);
	},
	methods: {
		handleClose() {
			this.$emit("close", false);
		},
		handleClick() {
			this.$refs.dialog.close();
		},
	},
};
