import { ref, onMounted, watch, computed } from "vue";
import { useStore } from "../helpers/compositionApi";
export function useLoadComponent(props) {
	const componentModule = ref(null);
	const store = useStore();
	const currentBrandId =
		store.state?.site?.workingSite?.brandId || store.state?.brand?.id;
	const currentBrand = store.getters?.["brand/getBrand"]?.(currentBrandId);

	function getBlockComponent(props) {
		const containerValue = props.isEditable
			? "container/CoreBlockContainer"
			: "CoreBlockContainer";
		const footer = props.isEditable
			? "footer/CoreBlockFooter"
			: "CoreBlockFooter";
		const types = {
			button: "CoreBlockButton",
			breadcrumbs: "CoreBlockBreadcrumbs",
			"contact-card": "CoreBlockContactCard",
			"contact-hero": "CoreBlockContactHero",
			columns: "CoreBlockColumns",
			container: containerValue,
			section: containerValue,
			accordion: containerValue,
			toggle: containerValue,
			tabs: containerValue,
			"content-card": "CoreBlockContentCard",
			divider: "CoreBlockDivider",
			file: "CoreBlockFile",
			grid: "CoreBlockGrid",
			heroComponent: "CoreBlockHeroComponent",
			hero: "CoreBlockHero",
			calculators: "CoreBlockCalculators",
			html: "CoreBlockHtml",
			icon: "CoreBlockIcon",
			image: "CoreBlockImage",
			media: "CoreBlockMedia",
			rows: "CoreBlockRows",
			"social-links": "CoreBlockSocialLinks",
			text: "CoreBlockText",
			video: "CoreBlockVideo",
			"pencil-banner": "CoreBlockPencilBanner",
			"rich-text": "CoreBlockRichText",
			form: "CoreBlockForm",
			calendly: "CoreBlockCalendly",
			map: "CoreBlockMap",
			select: "CoreBlockSelect",
			"anchor-link-nav": "CoreAnchorLinkNavigation",
			articles: "CoreBlockArticles",
			article: "CoreBlockArticleCard",
			footer,
			stickyButton: "CoreBlockStickyButton",
			repeat: "CoreBlockRepeat",
		};
		return types[props.settings?.type] || "CoreBlockUnknown";
	}
	async function loadComponent() {
		const type = getBlockComponent(props);
		try {
			// try client specific block
			const module = await import(
				`../components/${currentBrand?.handle}/block/${type}.vue`
			);
			return (componentModule.value = module.default || module);
		} catch (error) {
			// fallback to core block
			try {
				// try core block
				const fallbackModule = await import(
					`../components/block/${type}.vue`
				);
				componentModule.value =
					fallbackModule.default || fallbackModule;
			} catch (error) {
				// fallback to unknown block
				const unknownModule = await import(
					`../components/block/CoreBlockUnknown.vue`
				);
				componentModule.value = unknownModule.default || unknownModule;
			}
		}
	}
	if (props.isEditable) {
		onMounted(() => loadComponent());

		watch(
			() => props.settings.type,
			() => loadComponent()
		);
	} else if (props.settings) {
		componentModule.value = computed(() => getBlockComponent(props));
	}

	return {
		componentModule,
	};
}
