import { render, staticRenderFns } from "./CoreBlockFooterContact.vue?vue&type=template&id=23edb203&scoped=true"
import script from "./CoreBlockFooterContact.vue?vue&type=script&setup=true&lang=js"
export * from "./CoreBlockFooterContact.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CoreBlockFooterContact.vue?vue&type=style&index=0&id=23edb203&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23edb203",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreIcon: require('/usr/src/app/Core.Library/src/components/icon/CoreIcon.vue').default})
