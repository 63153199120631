
export default {
	name: "CoreIconClose",
	props: {
		settings: {
			type: Object,
			required: false,
		},
		click: {
			type: Function,
			default: () => {},
		},
		size: {
			type: Number,
			default: 24
		}
	},
	computed: {
		alt() {
			return this.settings?.alt || this.settings?.icon;
		},
		id() {
			return this.settings?.id || this.settings?.icon;
		}

	},
};
