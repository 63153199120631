import CoreBlockFooterContact from "./CoreBlockFooterContact.vue";
import { computed } from "vue";


export default {
  __name: 'CoreBlockFooterContactGroup',
  props: {
	site: {
		type: Object,
		required: true,
	},
},
  setup(__props) {

const props = __props;



const footer = computed(() => {
	return props.site.footer;
});

const contact1 = computed(() => {
	return footer.value["contact-1"];
});

const showContact1 = computed(() => {
	if (Object.prototype.hasOwnProperty.call(footer.value, "contact-1-show")) {
		return footer.value["contact-1-show"];
	}
	return true;
});

const contact2 = computed(() => {
	return footer.value["contact-2"];
});

const showContact2 = computed(() => {
	if (Object.prototype.hasOwnProperty.call(footer.value, "contact-2-show")) {
		return footer.value["contact-2-show"];
	}
	return true;
});

const contact3 = computed(() => {
	return footer.value["contact-3"];
});

const showContact3 = computed(() => {
	if (Object.prototype.hasOwnProperty.call(footer.value, "contact-3-show")) {
		return footer.value["contact-3-show"];
	}
	return true;
});

return { __sfc: true,props, footer, contact1, showContact1, contact2, showContact2, contact3, showContact3, CoreBlockFooterContact }
}

}