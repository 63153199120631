import Mustache from "mustache";

/**
 * Renders data using Mustache.js
 * @param {string} value - The string to be rendered.
 * @param {Object} object - The data to be used in the rendering.
 * @returns {string|null} The rendered string or null if the value is not a string or if an error occurs during rendering.
 */
const renderData = (value, object) => {
	if (typeof value !== "string") return null;
	// adding 2 opening brackets causes mustache to error, so we have to make sure both
	// closing brackets are there before rendering data
	if (value.replace(/(^<\w*>)|(<\/\w*>$)/gi, "")) {
		const hasAllBrackets = ["{{", "}}"].every((item) =>
			value.includes(item)
		);
		try {
			return hasAllBrackets ? Mustache.render(value, object) : value;
		} catch (err) {
			console.error("Rendering crashed:", err.message);
		}
	} else {
		return null;
	}
};

/**
 * Fetches the site object from the store.
 * @param {Object} store - The store object.
 * @returns {Object|null} The site object or null if it does not exist.
 */
const sitePath = (store) => {
	if (store?.state?.site) {
		const builderSite = store.state.site.workingSite;
		const generatorSite = store.state.site;
		return builderSite || generatorSite;
	}
};

/**
 * Formats the name based on the user data.
 * @param {string} name - The name to be formatted.
 * @param {Object} ddcUserData - The user data.
 * @returns {string} The formatted name.
 */
const formatName = (name, ddcUserData) => {
	const firstName = ddcUserData?.mktrLglFirstNm;
	const preferredFirstName = ddcUserData?.mktrPrefFirstNm;
	const mktrPrefFirstNmRegex = /"[^"]*"/gi;
	if (preferredFirstName && firstName !== preferredFirstName) return name;
	return name?.replace(mktrPrefFirstNmRegex, "");
};

export { renderData, formatName, sitePath };
